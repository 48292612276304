@media only screen and (orientation: portrait) {
    html {
        font-size: 2rem;
    }
}
body {
    font-family: "verdana";
    margin: 0px;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

ol {
    display: block;
    list-style-type: decimal;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
}

a:hover {
    color: #555;
}

a:active {
    color: #999;
}

.topMenu {
    background-color: lightgray;
}

.topMenu a {
    font-size: 1.5rem;
    padding: 10px 20px;
    display: inline-block;
    font-weight: bold;
}


.genealogieIndividualPage {
    font-size: 0.8rem;
}

.genealogiePatronymPage {
    font-size: 0.8rem;
}

.genealogiePatronymPage a {
    display: inline-block;
    width: 15rem;
}

.navToggle {
    font-size: 2rem;
    font-weight: bolder;
    margin-left: 1rem;
}

.verticalNavPane {
    position: absolute;
    background-color: rgba(245, 245, 245, 0.96);
    padding: 0.1rem 1rem;
    left: 1rem;
}

.verticalNavPane .headerDefault {
    font-weight: bold;
    display: block;
}

.verticalNavPane .header0 {
    font-size: 1rem;
    padding-top: 1rem;
}

.verticalNavPane .header1 {
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-top: 0.5rem;
}

.verticalNavPane .header2 {
    font-size: 0.8rem;
    padding-left: 2rem;
}

.verticalNavPane .linkDefault {
    font-size: 0.8rem;
    display: inline-block;
    padding-right: 1rem;
}

.verticalNavPane .link0 {
}

.verticalNavPane .link1 {
    padding-left: 1rem;
}

.verticalNavPane .link2 {
    padding-left: 2rem;
}

.verticalNavPane .link3 {
    padding-left: 3rem;
}

.verticalNavPane .paneLinkCount {
    font-size: 0.8rem;
}

.thumbSlider {
    width: 20rem;
    margin-left: 2rem;
    vertical-align: super;
}

.homeThumbnails
{
    display:inline-block;
    text-align:center;
    font-size:12px;
}

.currentCategory {
    font-size: 1.5rem;
    padding: 10px 10px;
    font-weight: bold;
    text-align: center;
}

.monnaiesList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.oneMonnaie {
    margin: 10px;
    text-align: center;
    font-size: 12px;
}

.oneOrTwoThumbnails {
}

.oneMonnaieThumbnail {
    max-width: 100%;
    max-height: 100%;
}

.fullSizeImageBackground {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.whiteBackground {
    background-color: white;
}

.blackBackground {
    background-color: black;
}

.fullSizeImgBlackBackground {
    border: 1px solid white;
    max-width: 99%;
    max-height: 99%;
}

.blackBackground button {
    background-color: black;
    color:white;
}

.whiteBackground button {
    background-color: white;
    color: black;
}

.fullSizeImgWhiteBackground {
    max-width: 100%;
    max-height: 100%;
}

.fullSizeImg {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.fullSizeImageButtons {
    position: fixed;
    right: 0;
    top: 0;
    margin: 0.2rem;
}

.nextButton {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0.2rem;
}
.previousButton {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0.2rem;
}

.fullSizeImageBackground button {
    font-size: 2rem;
    font-weight: bolder;
    margin: 0.2rem;
    line-height: 2rem;
}

.photoThumbnailImg {
    margin: 0.3rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* videos */
.oneVideoThumbnail {
    max-width: 256px;
    max-height: 256px;
    margin: 0.3rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.videoPlayer {
    position: fixed;
    border: 1px solid black;
    left: 50%;
    top: 50%;
    max-width: 70%;
    max-height: 70%;
    transform: translate(-50%, -50%);
}

.videoPlayerTopBar {
    background-color: white;
    text-align: right;
}

.videoPlayerCloseIcon {
    font-size: 2rem;
    font-weight: bolder;
    margin: 0.2rem;
    line-height: 2rem;
}

/* music */
.musicPage {
    margin: 20px;
}

.COMPOSER {
    font-size: 25px;
    font-weight: bold;
}

.COMPOSITION {
    font-size: 14px;
    font-weight: bold;
}

.ARTISTS {
    font-size: 12px;
}

.filmsPage {
    margin: 25px;
}

.pleiadePage {
    margin: 25px;
}
